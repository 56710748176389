.projects-page {
    .text-zone {
        position: relative;
        width: 100%;
        max-width: 90%;
        text-align: left;
        overflow-y: auto;
        margin: 0 auto;
        padding: 20px 0;

        h1 {
            font-size: 50px;
            font-family: 'Coolvetica';
            color: #000000;
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 40px;
            position: relative;
        }

        .custom-card-content {
            font-size: 20px;
            margin-bottom: 40px; // Added margin to space out sections

            img, iframe, video {
                width: 30%;
                height: auto;
                border: #000000 solid;
                border-width: 1px;
            }

            .custom-card-text {
                opacity: 0;
                animation: fadeIn 0.5s 2.25s forwards;
            }

            .float-right {
                margin-left: 20px;
                float: right;
            }

            .float-left {
                margin-right: 20px;
                float: left;
            }

            &::after {
                content: "";
                display: table;
                clear: both;
            }
        }

        .hr-separator {
            color: #ffffff;
            border: 1.5px solid #141331;
            margin: 20px 0;
        }
    }
}

@media (max-width: 770px) {
    .projects-page .text-zone {
        padding: 10px;

        .custom-card-content {
            font-size: 18px;

            img, iframe, video {
                width: 100%;
                margin: 10px auto;
                display: block;
                float: none;
            }

            .float-right,
            .float-left {
                float: none;
                margin: 0 auto;
            }
        }

        h1 {
            font-size: 35px;
        }
    }
}

@media (max-width: 600px) {
    .projects-page .text-zone {
        padding: 5px;

        .custom-card-content {
            font-size: 16px;

            img, iframe, video {
                width: 70%;
                margin: 10px auto;
                display: block;
                float: none;
            }
        }

        h1 {
            font-size: 25px;
        }
    }
}

@media (orientation: landscape) and (max-height: 600px) {
    .projects-page .text-zone {

        .custom-card-content {
            img, iframe, video {
                margin: 10px auto;
                display: block;
                float: none;
            }


            iframe, video {
                width: 70%;
            }

            img {
                width: 50%;
            }
        }
    }
}