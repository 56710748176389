.main-content {
    padding-top: 20px;
    padding-left: 12%; /* Adjust Padding for Navbar */
    overflow-x: hidden; /* Prevent Horizontal Overflow */
    position: relative; /* Ensure Relative Positioning */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin-right: 12%;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.tags {
    font-family: 'Courier New', Courier, monospace;
    color: #333;
    opacity: 0.7;
    font-size: 1.5em;
}

.top-tags {
    position: absolute;
    top: 10px;
    left: 20px;
    padding-bottom: 20px;
}

.bottom-tags {
    position: absolute;
    bottom: 10px;
    left: 20px;
}

.container {
    width: 100%;
    height: auto;
    min-height: 566px;
    position: relative;
    margin: 0 auto;
    will-change: contents;
    opacity: 0;
    top: 5%;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
    color: #141331;
    font-family: 'Coolvetica';
}

.container img {
    max-width: 100%;
    height: auto;
    display: block;
}

.supertitle {
    &::before {
        content: "fmt.Printf('";
        font-family: 'Cabin', sans-serif;
        font-size: 18px;
        color: #141331;
        margin-right: 10px;
        left: 15px;
        opacity: 0.6;
    }

    &::after {
        content: "')";
        font-family: 'Cabin', sans-serif;
        font-size: 18px;
        color: #141331;
        margin-left: 10px;
        animation: fadeIn 1s 1.7s backwards;
        opacity: 0.6;
    }
}

h1 {
    padding-top: 10px;
    margin-bottom: 10px;
    font-size: 40px;
    font-weight: 400;
}

h2 {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1.5px;
    animation: fadeIn 1s 2.25s backwards;
}

.section-separator {
    margin-top: 100px;
}

.hr-separator {
    color: #ffffff;
    border: 1.5px solid #141331;
}

.external-links {
    text-decoration: none;
    color: #1c05c8;
}

@media (max-width: 770px) {
    .main-content {
        padding-left: 15%; /* Adjust padding to leave space for the navbar */
        padding-right: 5%;
        flex-direction: column;
        align-items: center;
        margin-right: 5%;
    }

    .tags {
        font-size: 1.5em;
    }

    .top-tags {
        top: 5px;
        left: 10px;
        padding-bottom: 15px; // Adjust padding for smaller screens
    }

    .bottom-tags {
        bottom: 5px;
        left: 10px;
    }

    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1em;
    }
}

@media (max-width: 600px) {
    .main-content {
        padding-left: 20%; /* Further Adjust Padding for Smaller Screens */
        padding-right: 2%;
        margin-right: 2%;
    }

    .tags {
        font-size: 0.8em;
    }

    .top-tags {
        top: 5px;
        left: 5px;
        padding-bottom: 10px; // Adjust padding for even smaller screens
    }

    .bottom-tags {
        bottom: 5px;
        left: 5px;
    }

    h1 {
        font-size: 1.5em;
    }

    h2 {
        padding-top: 10px;
        font-size: 0.8em;
    }
}

@media (max-width: 600px) or (max-height: 690px) {
    .main-content {
        padding-left: 0;
        padding-right: 15px;
        margin-right: 2%;
    }
}

@media (max-width: 600px) {
    .main-content {
        padding-top: 17%;
    }
}

@media (max-height: 515px) {
    .main-content {
        padding-top: 15%;
    }
}