html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Calibri', 'Arial', sans-serif;
  color: #154c79;
  background-color: #6ebbf6;
  overflow: hidden;
  display: block;
  --webkit-font-smoothing: antialiased;
  --moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
