.interests-page {
    .text-zone {
        position: relative;
        width: 100%;
        max-width: 90%;
        text-align: left;
        overflow: hidden;
        margin: 0 auto;
        padding: 20px 0;

        h1 {
            font-size: 50px;
            font-family: 'Coolvetica';
            color: #000000;
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 40px;
            position: relative;
        }
    }

    .photo-gallery {
        height: 400px;
        margin: 25px;

        .slick-list {
            padding: 0 !important;
        }

        .slick-track {
            display: flex;
        }

        .photo-items {
            height: 300px;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            position: relative;
            border: 3px solid black;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .slick-dots {
            bottom: -30px;
            white-space: nowrap;
            text-align: center;
            width: 100%;
        }

        .slick-dots li {
            display: inline-block;
            margin: 0 3px;
        }

        .slick-dots li button:before {
            font-size: 12px;
            color: #FFFFFF;
        }

        .slick-dots li.slick-active button:before {
            color: #000000;
        }
    }

    .tooltip {
        display: inline-block;
        text-align: center;
        font-size: 12px;
        background: #000000;
        color: #0e92e5;
        margin: 2px;
        border-radius: 1px;
    }

    /* Vega-Lite visualization styles */
    #vis.vega-embed {
        width: 100%;
        display: flex;
    }

    #vis.vega-embed details,
    #vis.vega-embed details summary {
        position: relative;
    }
}

@media (max-width: 770px) {
    .interests-page {
        .text-zone {
            padding: 10px;

            h1 {
                font-size: 35px;
            }
        }

        .photo-gallery {
            height: 250px;
            justify-content: center;
            align-items: center;

            .photo-items {
                height: 200px;
                border: 2px solid black;

                img {
                    height: 200px;
                    max-height: 200px;
                }

                .slick-dots li button:before {
                    font-size: 8px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .interests-page {
        .text-zone {
            padding: 5px;

            h1 {
                font-size: 25px;
            }
        }

        .photo-gallery {
            height: 150px;
            justify-content: center;
            align-items: center;

            .photo-items {
                height: 125px;
                border: 1.75px solid black;

                img {
                    max-height: 125px;
                }
            }

            .slick-dots li button:before {
                font-size: 5px;
                color: #FFFFFF;
            }
        }
    }
}
