.iconbar {
    text-align: right;
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    width: auto;
    padding: 2px;
    margin-right: 15px; /* Margin for Desktop View */

    .social-links {
        display: flex;
        flex-direction: row;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            a {
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                line-height: 16px;

                &:hover svg {
                    color: #D9FEC0;
                }
            }
        }
    }
}

@media (max-height: 690px) and (min-width: 600px) {
    .iconbar {
        display: none;
    }
}

@media (max-width: 600px) {
    .iconbar {
        top: auto;
        bottom: 0;
        right: 0;
        margin-right: 0; /* Reset Margin for Mobile View */
        padding-bottom: 20px;

        .social-links {
            flex-direction: column;

            li {
                a {
                    font-size: 20px;
                    padding: 2px;
                }
                margin-left: 0;
                margin-bottom: 10px;
            }
        }
    }
}

@media (max-height: 400px) {
    .iconbar {
        display: none;
    }
}
