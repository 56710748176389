.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Ensure Minimum Height */
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: 0;
    overflow: hidden;  /* Ensure No Overflow */

    .text-zone {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 90%;
        text-align: left;
        margin: 0 auto;
        padding: 20px 0;
        flex-grow: 1; /* Allow Text-Zone to Expand */
        flex-shrink: 0; /* Prevent Shrinking */
        justify-content: center; /* Center Content Vertically */

        h1 {
            font-size: 50px;
            font-family: 'Coolvetica';
            color: #000000;
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 40px;
            position: relative;
        }

        h2 {
            font-size: 18px;
            font-family: 'Coolvetica';
            color: #000000;
            font-weight: 400;
        }

        .custom-card-content {
            display: flex;
            align-items: center; /* Center Image Vertically Within Text */
            justify-content: space-between;
            width: 100%;
            font-size: 1.2em;
            line-height: 1.5em;
            margin: 1em 0;
            text-align: left;

            .intro-text {
                flex: 1;
                text-align: left;
            }
        }

        img {
            width: 30%;
            height: auto;
            float: right;
            opacity: 0;
            margin-left: 20px;
            border: #000000 solid;
            border-width: 1px;
            animation: fadeIn 0.5s 2.25s forwards;
        }

        .tags {
            font-family: 'Courier New', Courier, monospace;
            color: #333;
            opacity: 0.7;
        }

        .top-tags {
            position: absolute;
            top: -30px;
            left: 0;
        }

        .bottom-tags {
            position: absolute;
            bottom: -30px;
            left: 0;
        }

        .supertitle {
            display: inline-block; /* Ensure Inline-Block to Avoid Overlap */
            margin-bottom: 20px;
        }
    }

    .stage-cube-container {
        width: 150px;
        height: 150px;
        perspective: 2000px;
        right: 20px; /* Adjust Right Alignment as Needed */
        top: 50%;
        transform: translateY(-50%);
        margin-left: 75%;
        padding-right: 4%;
    }

    .cubespinner {
        width: 100%;
        height: 100%;
        position: relative;
        transform-style: preserve-3d;
        animation: spincube 17.5s infinite ease-in-out;
        transform-origin: 100px 100px 0;
        margin-left: calc(50% - 100px);
        margin-top: calc(65%);

        div {
            position: absolute;
            width: 100%;
            height: 100%;
            border: 1px solid #000000;
            background: rgba(89, 150, 87, 0.4);
            text-align: center;
            font-size: 50px; /* Adjust Font Size for Small Screens */
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.5);
        }

        .face1 { transform: translateZ(75px); }
        .face2 { transform: rotateX(-90deg) rotateY(90deg) translateZ(75px); }
        .face3 { transform: rotateY(90deg) rotateX(90deg) translateZ(75px); }
        .face4 { transform: rotateY(180deg) rotateZ(90deg) translateZ(75px); }
        .face5 { transform: rotateY(-90deg) rotateZ(90deg) translateZ(75px); }
        .face6 { transform: rotateX(-90deg) translateZ(75px); }
    }

    @keyframes spincube {
        to { transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
        16% { transform: rotateY(-90deg) rotateZ(90deg); }
        33% { transform: rotateY(-90deg) rotateX(90deg); }
        50% { transform: rotateY(-180deg) rotateZ(90deg); }
        66% { transform: rotateY(-270deg) rotateX(90deg); }
        83% { transform: rotateX(90deg); }
    }

    @media (min-width: 770px) {
        .text-zone {
            .custom-card-content {
                font-size: 1.5em;
                text-align: center;
                bottom: 20px;
            }

            .profile-img {
                width: 30%;
                height: auto;
                align-self: center;
            }
        }
    }

    @media (max-width: 600px) or (max-height: 690px) {
        .cubespinner {
            margin-top: calc(100%);
        }
    }

    @media (max-width: 500px) {
        .cubespinner {
            margin-top: 0;
        }

        .text-zone {
            padding-top: 25%;
        }
    }


    @media (max-width: 770px) {
        .home-page {
            padding-top: 0;
            padding-bottom: 0;
            margin-right: 0;
        }

        .text-zone {
            position: relative;
            transform: none;
            width: 100%;
            margin: 0 auto;
            text-align: center;

            img {
                width: 50%;
                margin: 10px auto;
                display: block;
                float: none;
            }

            h1 {
                font-size: 35px;
            }

            h2 {
                font-size: 16px;
            }

            .custom-card-content {
                flex-direction: column; /* Stack Content Vertically */
                text-align: center;

                .not-on-mobile {
                    display: none;
                }
            }

            .profile-img {
                width: 60%;
                margin: 15px auto;
                display: block;
                text-align: center;
            }
        }

        .stage-cube-container {
            width: 75px;
            height: 50px;
            margin: 40px auto;
            right: 0;
        }

        .cubespinner {
            width: 50%;
            height: 50%;

            div {
                width: 150px;
                height: 150px;
            }
        }
    }

    @media (orientation: landscape) and (max-height: 600px) {
        .text-zone {
            padding-top: 25%;
            h1 {
                font-size: 20px;
                margin-bottom: 10px; /* Adjusted Margin to Prevent Cutoff */
            }

            h2 {
                font-size: 12px;
                margin-bottom: 10px; /* Adjusted Margin to Prevent Cutoff */
            }

            .custom-card-content {
                font-size: 0.8em;
                flex-direction: column;
                align-items: center;
                text-align: center;
                padding-bottom: 20px; /* Adjusted Padding to Prevent Cutoff */

                .not-on-mobile {
                    display: none;
                }
            }

            .profile-img {
                width: 60%;
                margin: 15px auto;
                display: block;
                text-align: center;
            }
        }
    }
}
