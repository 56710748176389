.nav-bar {
    background: #141331;
    position: fixed; /* Fixed Position to Stay in Place While Scrolling */
    top: 0;
    display: flex;
    justify-content: space-between; /* Distribute Space Equally */
    z-index: 3;

    width: 12%;
    max-width: 120px;
    height: 100vh; /* Use Full Height of the Viewport */
    flex-direction: column;

    .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10% 10%;

        img {
            display: block;
            margin: 10% 0;
            width: 95%;
            height: auto;
        }
    }

    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between; /* Ensure space between nav-links and social-links */
        height: 100%; /* Full height of the container */

        .nav-links {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1; /* Allow to Grow and Take Available Space */
            justify-content: space-around; /* Evenly Distribute */

            a {
                margin: 10px 0; /* Add Margin to Space Out Links */
            }

            a.active {
                svg {
                    color: #3EA542;
                }
            }
        }

        .social-links {
            display: flex;
            flex-direction: column;
            align-items: center;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                a {
                    padding: 8px 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    line-height: 16px;

                    &:hover svg {
                        color: #D9FEC0;
                    }
                }
            }
        }
    }

    nav {
        a {
            font-size: 25px;
            color: #4D4D4E;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 75px;
            height: 75px;
            width: 100%;
            position: relative;
            text-decoration: none;

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #D9FEC0;

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 18px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                height: 75px;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-out;
            }

            &:first-child {
                &:after {
                    content: 'Home';
                }
            }
        }
    }
}

/* Change Hovering Text Based on Screen Size. */
@media (min-width: 925px) or (min-height: 690px) {
    .nav-bar {
        nav {
            a#experiences-link {
                &:after {
                    content: 'Experiences';
                }
            }

            a#projects-link {
                &:after {
                    content: 'Projects';
                }
            }

            a#interests-link {
                &:after {
                    content: 'Interests';
                }
            }
        }
    }
}
@media (max-width: 925px) or (max-height: 690px) {
    .nav-bar {
        nav {
            a#experiences-link {
                &:after {
                    content: 'Work';
                }
            }

            a#projects-link {
                &:after {
                    content: 'Projects';
                }
            }

            a#interests-link {
                &:after {
                    content: 'Fun';
                }
            }
        }
    }
}

@media (max-width: 770px) {
    .nav-bar {
        width: 15%;
        max-width: none;

        nav {
            a {
                font-size: 20px;

                &:after {
                    font-size: 14px;
                }
            }
        }

        ul li a {
            font-size: 20px;
        }
    }
}

/* Social Links Rendered by Iconbar. */
@media (min-width: 600px) and (min-height: 690px) {
    .nav-bar {
        .content-container {
            .social-links {
                display: none;
            }
        }
    }
}

/* Social Links Rendered by Navbar. */
@media (max-width: 600px) or (max-height: 690px) {
    .nav-bar {
        width: 100%;
        height: auto;
        position: fixed;
        top: 0;
        left: 0;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        max-width: none;
        z-index: 3;

        .logo {
            padding: 0;
            flex-direction: row;
            align-items: center;

            img {
                margin: 0;
                width: auto;
                height: 40px;
            }

            #insignia {
                height: 40px;
            }
        }

        .content-container {
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            height: auto;
            padding: 0;

            .nav-links {
                flex-direction: row;
                align-items: center;
            }

            .social-links {
                flex-direction: row;
                margin-right: 20px;

                li {
                    margin-left: 10px;

                    a {
                        padding: 0;
                        font-size: 20px;
                    }
                }
            }
        }

        .nav-links {
            a {
                font-size: 20px;
                height: auto;
                width: auto;
                margin: 0 10px;

                &:hover:after {
                    top: -30px;
                    left: -10px;
                }

                &:after {
                    font-size: 10px;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .nav-bar {
        width: 100%;
        height: auto;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        .nav-links {
            a {
                font-size: 18px;

                &:after {
                    font-size: 12px;
                }
            }

            a#experiences-link {
                &:after {
                    content: 'Work';
                }
            }
        }

        ul li a {
            font-size: 18px;
        }

        .content-container {
            .social-links {
                display: none;

                .social-icons {
                    display: none;
                }
            }
        }
    }
}

@media (orientation: landscape) and (max-height: 600px) {
    .nav-bar {
        width: 100%;
        height: auto;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        nav {
            a {
                font-size: 16px;

                &:after {
                    font-size: 10px;
                }
            }

            a#experiences-link {
                &:after {
                    content: 'Work';
                }
            }
        }

        ul li a {
            font-size: 16px;
        }
    }
}