footer {
    text-align: right;
    font-size: 12px;
    color: #000000;
    margin-top: 1%;
    margin-right: 1%;
    position: relative;
    z-index: -1;
}

@media (max-width: 770px) {
    footer {
        padding-right: 10px;
        padding-bottom: 2px;
        font-size: 10px;
    }
}